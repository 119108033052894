export const fillArray = <T>(n: number, fn: (i: number) => T): T[] =>
  Array.from(Array(n)).map((_, i) => fn(i))

/**
 * Allows you to split your array in N chunks of items
 *
 * @param {any[]} array - the array to chunk from
 * @param {number} size - the number of chunks you want
 * @returns {any[][]} - an array with the chunked items
 */
export const chunk = (array: any[], size: number) =>
  Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, i * size + size)
  )
